<script setup lang="ts">
import type { Schemas } from "#shopware";

const {
  appliedPromotionCodes,
  addPromotionCode,
  removeItem,
  refreshCart,
  cart,
} = useCart();
const { getErrorsCodes } = useCartNotification();
const { apiClient } = useShopwareContext();
const { pushError } = useNotifications();
const { t } = useI18n();
const { getPaymentMethods } = useCheckout();

const voucherCode = ref("");
const isLoading = ref(false);

const addPromotionCodeHandler = async (promotionCode: string = "") => {
  isLoading.value = true;

  try {
    await addPromotionCode(promotionCode || voucherCode.value || "");

    getErrorsCodes()?.forEach((element) => {
      pushError(t(`errors.${element.messageKey}`, { ...element }));
    });

    voucherCode.value = "";
  } catch (error) {
    pushError(error as string);
  } finally {
    await getPaymentMethods({ forceReload: true });
    isLoading.value = false;
  }
};

const removeItemHandler = (appliedPromotionCode: Schemas["LineItem"]) => {
  removeItem(appliedPromotionCode);
  getErrorsCodes()?.forEach((element) => {
    pushError(t(`errors.${element.messageKey}`, { ...element }));
  });
};

const removePluginVoucher = async (voucherCode: any) => {
  await apiClient.invoke(" delete /checkout/cart/giftcard", {
    body: {
      codes: [voucherCode],
    },
  });
  await refreshCart();
};

const showPromotionCodes = computed(() => {
  return (
    // shopware vouchers
    appliedPromotionCodes.value.length > 0 ||
    // plugin vouchers
    cart.value?.extensions["lae-giftcards"]?.length
  );
});

const activePromotion = ref<BannerPromotion>();

const fetchActivePromotion = async () => {
  try {
    activePromotion.value = await $fetch(`/api/banner-promotion/getPromotion`);
    if (!activePromotion.value?.promotionStyling.showInCheckout) {
      activePromotion.value = undefined;
    }
  } catch (e) {
    console.error(e);
    activePromotion.value = undefined;
  }
};

// watch(cart, () => {
//   console.log(cart.value);
// });
// watch(appliedPromotionCodes, () => {
//   console.log(appliedPromotionCodes.value);
// });

await fetchActivePromotion();
</script>

<template>
  <div class="p-3 bg-white relative">
    <ScheppachSpinner v-if="isLoading" />

    <div
      v-if="
        activePromotion &&
        !appliedPromotionCodes.some(
          (pc) => pc.payload.promotionId == activePromotion?.shopwareId,
        )
      "
      class="flex flex-row w-full py4 px-2 mb-2 bg-scheppach-neutral-50 rounded"
    >
      <div
        v-if="activePromotion.discountValue"
        class="flex justify-between p3 font-bold w-full items-center bg-scheppach-primary-50 b b-scheppach-primary-500 rd-l b-r-none c-scheppach-primary-500"
      >
        <div>{{ activePromotion.code || activePromotion.name }}</div>
        <span>
          -
          {{ activePromotion.discountValue.toFixed(2) }}
          {{ activePromotion.discountType == "PERCENTAGE" ? "%" : "€" }}
        </span>
      </div>
      <div
        class="p3 bg-scheppach-primary-500 c-white font-bold rd-r cursor-pointer"
        @click="() => addPromotionCodeHandler(activePromotion?.code)"
      >
        {{ $t("checkout.promoCode.apply") }}
      </div>
    </div>

    <ScheppachTextInput
      v-model="voucherCode"
      :label="t('checkout.promoCode.voucherCode')"
      :placeholder="t('checkout.promoCode.enterVoucherPlaceholder')"
      @keyup.enter="addPromotionCodeHandler()"
    />
    <button
      class="c-white bg-scheppach-primary-500 hover:bg-scheppach-primary-700 w-full sm:w-auto px-6 py-2 mt-2 rd"
      @click="addPromotionCodeHandler()"
    >
      {{ $t("checkout.promoCode.apply") }}
    </button>

    <div v-if="showPromotionCodes">
      <div class="c-scheppach-primary-700 text-sm md:text-base mt-5 mb-1">
        {{ $t("checkout.promoCode.label") }}:
      </div>
      <!-- Codes comming from shopware -->
      <ul role="list" class="text-sm pl-0">
        <li
          v-for="appliedPromotionCode in appliedPromotionCodes"
          :key="appliedPromotionCode.id"
          class="flex justify-between pb-2"
        >
          <SharedPromotionCode
            class="flex-basis-75"
            :code="appliedPromotionCode.payload?.code"
            :label="appliedPromotionCode.label"
            :total-price="appliedPromotionCode.price?.totalPrice"
          />

          <button
            v-if="appliedPromotionCode.payload?.code"
            class="c-scheppach-primary-700 bg-scheppach-shades-0 hover:bg-scheppach-primary-50 b-scheppach-primary-700 rounded border-1 p-2"
            type="button"
            @click="removeItemHandler(appliedPromotionCode)"
          >
            {{ $t("checkout.promoCode.remove") }}
          </button>
          <button
            v-else
            class="c-scheppach-neutral-500 bg-scheppach-shades-0 b-scheppach-neutral-500 rounded border-1 p-2 cursor-not-allowed"
            type="button"
          >
            {{ $t("checkout.promoCode.remove") }}
          </button>
        </li>
      </ul>
      <!-- Codes comming from plugin -->
      <ul role="list" class="text-sm pl-0">
        <li
          v-for="voucher in cart.extensions['lae-giftcards']"
          :key="voucher.id"
          class="flex justify-between pb-2"
        >
          <SharedPromotionCode
            class="flex-basis-75"
            :code="voucher?.name"
            :label="voucher?.name"
            :total-price="0 - voucher?.appliedAmount"
          />

          <button
            class="c-scheppach-primary-700 bg-scheppach-shades-0 hover:bg-scheppach-primary-50 b-scheppach-primary-700 rounded border-1 p-2"
            type="button"
            @click="removePluginVoucher(voucher.code)"
          >
            {{ $t("checkout.promoCode.remove") }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
